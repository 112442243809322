import { SButton, SText, SvgIcon } from "base-components";
import { ButtonWithBg } from "../../../../components";
import Locale from "locale";
import React, { useMemo } from "react";
import { View } from "react-native";
import styles from "./styles";
import { ILuckyTradeSpinReward } from "services/LuckyDrawService";
import moment from "moment";
import {
  getRewardAction,
  getRewardIcon,
  getRewardName,
} from "apps/spin-wheel/screens/Home/components/utils";

interface Props {
  item: ILuckyTradeSpinReward;
  onPress(url: string): void;
  withTime?: boolean;
  isNewVersionEnabled?: boolean;
}

const ValidGiftItem: React.FC<Props> = ({
  item,
  onPress,
  withTime,
  isNewVersionEnabled,
}) => {
  if (!item) return null;
  const disabled = item.status === "used";
  const format = withTime ? "DD/MM/YYYY HH:mm" : "DD/MM/YYYY";
  const expiredDate = item.expiry_timestamp
    ? moment(item.expiry_timestamp * 1000).format(format)
    : "N/A";
  const giftIcon = useMemo(
    () =>
      getRewardIcon({
        gifttype: item.lucky_trade_gift.gift_type,
        coinType: item?.reward_params.currency,
        amount: item?.reward_params.amount,
        isNewVersionEnabled,
      }),
    [
      isNewVersionEnabled,
      item.lucky_trade_gift.gift_type,
      item?.reward_params.amount,
      item?.reward_params.currency,
    ]
  );

  const newPage = useMemo(() => {
    if (isNewVersionEnabled) {
      return {
        containerStyle: styles.newContainer,
      };
    }
    return {
      containerStyle: styles.container,
    };
  }, [isNewVersionEnabled]);
  const giftName = getRewardName(item.lucky_trade_gift, item?.reward_params);
  const { label, url } = getRewardAction(
    item.lucky_trade_gift.gift_type,
    item.status
  );

  return (
    <View style={newPage.containerStyle}>
      <View style={[styles.coinLogo, disabled && styles.transperant50]}>
        <SvgIcon name={String(giftIcon)} size={52} />
      </View>
      <View style={[styles.giftContent, disabled && styles.transperant50]}>
        <SText bold size={15} style={styles.giftTitle}>
          {giftName}
        </SText>
        <SText style={styles.giftDescription}>{`${Locale.translate(
          "expire"
        )}: ${expiredDate}`}</SText>
      </View>
      <SButton title={Locale.translate(label)} onPress={() => onPress(url)} />
    </View>
  );
};

export default ValidGiftItem;
