import React, { useMemo, useEffect } from "react";
import { Image, View } from "react-native";
import { SText, SvgIcon } from "base-components";
import CacheImageService from "services/CacheImageService";
import COLORS from "utils/CommonColors";
import ProgressBar from "apps/crypto-quiz/components/Progess/Bar";
import { scale } from "utils/StyleSheet";
import Locale from "locale";
import styles from "./styles";
import { GlobalState } from "stores/types/store";
import { useDispatch, useSelector } from "react-redux";

interface Props {
  onReady(background: any): void;
}

const PRELOAD_IMGS = [
  "board",
  "new-board",
  "pointer",
  "new-pointer",
  "star-background",
  "new-background",
  "wheel",
  "new-wheel",
  "wheel-aza",
  "table-header",
  "new-table-header",
  "pepe-stop",
  "pepe-helmet",
];

const checkDate = new Date();

const percent = 100 / 2;
const SplashScreen: React.FC<Props> = (props) => {
  const [progress, setProgress] = React.useState(0);
  const { isCampaignTime, isNewVersionEnabled } = useSelector(
    (state: GlobalState) => state.campaign
  );
  const dispatch = useDispatch();

  useEffect(() => {
    const promiseApi = (delay: number) =>
      new Promise((resolve) => setTimeout(resolve, delay));
    const loadData = async () => {
      let newProgress = 0;
      await Promise.all([
        CacheImageService.preloadListImg(PRELOAD_IMGS).then(() => {
          newProgress = newProgress + percent;
          setProgress(newProgress);
        }),
        promiseApi(1000).then(() => {
          newProgress = newProgress + percent;
          setProgress(newProgress);
        }),
        promiseApi(3000),
      ]);
      setTimeout(() => {
        if (isNewVersionEnabled) {
          props.onReady(CacheImageService.getCachedImg("new-background"));
        } else {
          props.onReady(CacheImageService.getCachedImg("star-background"));
        }
      }, 500);
    };
    loadData();
  }, [dispatch, props.onReady]);

  const newPage = useMemo(() => {
    if (isNewVersionEnabled) {
      return {
        imageBg: require(`assets/spin-wheel/new-splash-${Locale.currLocale}.png`),
        bgStyle: styles.imageBg2,
      };
    }
    return {
      imageBg: require(`assets/spin-wheel/splash-${Locale.currLocale}.png`),
      bgStyle: styles.imageBg,
    };
  }, [isNewVersionEnabled]);

  return (
    <View style={styles.container}>
      <Image source={newPage.imageBg} style={newPage.bgStyle} />
      <View style={styles.content}>
        {!isCampaignTime && (
          <SvgIcon
            name="remitano-logo"
            width={84}
            height={20}
            style={styles.itemCenter}
          />
        )}
        <div />
        <View style={styles.progressBar}>
          <ProgressBar
            rounded
            width={scale(160)}
            progress={progress}
            fillColor={COLORS.primaryA}
          />
          <SText style={styles.appVersion}>
            {Locale.translate("version")}: 0.1.10
          </SText>
        </View>
      </View>
    </View>
  );
};

export default SplashScreen;
