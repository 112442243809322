import React, { useMemo } from "react";
import { Image, Pressable, ScrollView, View } from "react-native";
import ControlBoard from "./components/ControlBoard";
import Wheel, { WheelRef } from "./components/Wheel";
import ButtonWithIcon from "./components/ButtonWithIcon";
import RunningNoti from "./components/RunningNoti";
import { NavBar, SText, SvgIcon } from "base-components";
import Locale from "locale";
import RewardModal, { ModalRef } from "./components/RewardModal";
import { useNavigate } from "react-router-dom";
import LuckyDrawService, { IGiftType } from "services/LuckyDrawService";
import { useDispatch, useSelector } from "react-redux";
import { GlobalState } from "stores/types/store";
import SW_SCREENS from "../info";
import styles from "./styles";
import { getRewardIndex } from "./components/utils";
import RemitanoBrowser from "utils/RemitanoBrowser";
import MissionBoard, { MissionBoardRef } from "./components/MissionBoard";
import { scale } from "utils/StyleSheet";
import { sendEventLog } from "utils/EventLogger";
import { UserAction } from "stores/actions";
import AlertHelper from "utils/AlertHelper";
import VarHelper from "utils/VarHelper";
import ConfirmModal, { ModalConfirmRef } from "./components/ConfirmModal";
import UserService from "services/UserService";

const HomeScreen: React.FC = () => {
  const wheelRef = React.useRef<WheelRef>();
  const modalRef = React.useRef<ModalRef>(null);
  const modalConfirmRef = React.useRef<ModalConfirmRef>(null);
  const scrollRef = React.useRef<any>();
  const missionBoardRef = React.useRef<MissionBoardRef>(null);
  const [inSpin, setSpinStatus] = React.useState(false);
  const [reward, setReward] = React.useState<any>({
    lucky_trade_gift: {
      gift_name: "",
      gift_type: IGiftType.good_luck,
    },
    reward_params: {
      currency: "",
    },
  });
  const { lucky_trade_spin_balance, lucky_trade_token_balance } = useSelector(
    (state: GlobalState) => state.user
  );
  const { campaign_name } = useSelector((state: GlobalState) => state.campaign);
  const { isRemiBrowser } = useSelector((state: GlobalState) => state.device);
  const { isCampaignTime, isNewVersionEnabled } = useSelector(
    (state: GlobalState) => state.campaign
  );

  const dispatch = useDispatch();
  const navigate = useNavigate();

  React.useEffect(() => {
    const fetchData = async () => {
      if (campaign_name) {
        await Promise.all([
          LuckyDrawService.getSpinTurn(),
          LuckyDrawService.getTokenBalance(),
          UserService.getCurrentUser(),
        ]);
      }
    };
    fetchData();
  }, [campaign_name]);

  React.useEffect(() => {
    sendEventLog("view_luckydraw");
  }, []);

  const onOpenMyBag = () => {
    navigate(SW_SCREENS.MyRewardScreen.path);
  };

  const onOpenLeaderboard = () => {
    navigate(SW_SCREENS.LeaderboardScreen.path);
  };

  const onPlay = React.useCallback(
    async (acceptRisk = false) => {
      if (inSpin) return;
      if (lucky_trade_spin_balance === 0) {
        scrollRef.current?.scrollTo({
          y: missionBoardRef.current?.getPositionMission(),
          animated: true,
        });
        return;
      }
      sendEventLog("click_luckydraw_spin");
      setSpinStatus(true);
      try {
        const res = await LuckyDrawService.startSpin(
          lucky_trade_spin_balance,
          acceptRisk
        );
        if (res) {
          setReward(res);
          const rewardIndex = getRewardIndex(res);
          if (rewardIndex !== undefined) {
            wheelRef?.current && wheelRef?.current?.play(rewardIndex as number);
          }
        } else {
          setSpinStatus(false);
        }
      } catch (error: any) {
        setSpinStatus(false);
        if (error.error_code === "lucky_draw.error.bad_luck") {
          const hintNumber = VarHelper.randomInRange(1, 3);
          modalConfirmRef?.current?.show({
            title: Locale.translate(`badluck_title_${hintNumber}`),
            message: Locale.translate(`badluck_des_${hintNumber}`),
            confirmText: Locale.translate("continue_spin"),
            cancelText: Locale.translate("cancel_spin"),
          });
          return;
        }
        AlertHelper.showError(error);
      }
    },
    [inSpin, lucky_trade_spin_balance]
  );

  const onScrollToMission = React.useCallback(() => {
    scrollRef.current?.scrollTo({
      y: missionBoardRef.current?.getPositionMission(),
      animated: true,
    });
  }, []);

  const onAcceptRisk = () => {
    onPlay(true);
  };

  const showModalReward = () => {
    setSpinStatus(false);
    if (!reward?.lucky_trade_gift?.gift_type) return;
    if (reward.lucky_trade_gift.gift_type === IGiftType.spin) {
      dispatch({
        type: UserAction.UPDATE_SPIN_TURN,
        data: lucky_trade_spin_balance + reward.reward_params.amount,
      });
    }
    if (reward.lucky_trade_gift.gift_type === IGiftType.token) {
      dispatch({
        type: UserAction.UPDATE_TOKEN_BALANCE,
        data: lucky_trade_token_balance + reward.reward_params.amount,
      });
    }
    if (reward.lucky_trade_gift.gift_type === IGiftType.voucher) {
      sendEventLog("redeem_luckydraw_voucher", {
        voucher_id: reward?.reward_params?.voucher_id,
      });
    }
    modalRef?.current?.showReward();
  };

  const backHandle = () => {
    if (isRemiBrowser) return RemitanoBrowser.closeBrowser();
    navigate(SW_SCREENS.SplashScreen.path);
  };

  const isCloseToBottom = ({
    layoutMeasurement,
    contentOffset,
    contentSize,
  }: any) => {
    const paddingToBottom = scale(80);
    return (
      layoutMeasurement.height + contentOffset.y >=
      contentSize.height - paddingToBottom
    );
  };

  const newPage = useMemo(() => {
    if (isNewVersionEnabled) {
      return {
        iconMyBadge: "new_ic_mybag",
        iconLeaderBoard: "new_ic_leaderboard",
        fakeWheelStyle: styles.newFakeWheel,
        actionContainerStyle: styles.newActionContainer,
        iconSize: 68,
      };
    }
    return {
      iconMyBadge: "ic_mybag",
      iconLeaderBoard: "ic_leaderboard",
      fakeWheelStyle: styles.fakeWheel,
      actionContainerStyle: styles.actionContainer,
      iconSize: 52,
    };
  }, [isNewVersionEnabled]);

  return (
    <View style={styles.container}>
      <NavBar title={Locale.translate("mystery_planet")} onBack={backHandle} />
      <View style={styles.flex1}>
        <RunningNoti />
        <ScrollView
          ref={scrollRef}
          style={styles.flex1}
          onScroll={({ nativeEvent }) => {
            if (isCloseToBottom(nativeEvent)) {
              missionBoardRef.current?.getMissions();
            }
          }}
          scrollEventThrottle={400}
        >
          <View style={styles.mt20}>
            <View style={styles.gemTotal}>
              <Image
                source={require("assets/spin-wheel/small-gem.png")}
                style={styles.gem}
              />
              <SText bold size={16}>
                {lucky_trade_token_balance || 0}
              </SText>
            </View>
            <View style={newPage.fakeWheelStyle} />
            <ControlBoard onPlay={onPlay} />
            <View style={styles.absoluteWheel}>
              <Wheel
                ref={wheelRef}
                onShowReward={showModalReward}
                isNewVersionEnabled={isNewVersionEnabled}
              />
              <View style={newPage.actionContainerStyle}>
                <ButtonWithIcon
                  title={Locale.translate("my_bag")}
                  icon={newPage.iconMyBadge}
                  onPress={onOpenMyBag}
                  size={newPage.iconSize}
                  textStyle={styles.upTo8}
                />
                <ButtonWithIcon
                  title={Locale.translate("rank")}
                  icon={newPage.iconLeaderBoard}
                  onPress={onOpenLeaderboard}
                  size={newPage.iconSize}
                  textStyle={styles.upTo8}
                />
              </View>
            </View>
          </View>
          {campaign_name ? (
            <MissionBoard
              ref={missionBoardRef}
              isNewVersionEnabled={isNewVersionEnabled}
            />
          ) : null}
        </ScrollView>
      </View>
      <RewardModal
        ref={modalRef}
        reward={reward}
        isCampaignTime={isCampaignTime}
        isNewVersionEnabled={isNewVersionEnabled}
        onScrollToMission={onScrollToMission}
      />
      <ConfirmModal ref={modalConfirmRef} onConfirm={onAcceptRisk} />
    </View>
  );
};

export default HomeScreen;
