const words = {
  kyc_to_continue: "Vui lòng kyc lv2 để chơi",
  kyc: "KYC",
  my_reward: "Kho quà",
  share_title: "Khám phá hành tinh, Rinh thêm quà xịn cùng Remitano nhé #MysteryPlanet #cryptogames",
  mission_swap: "Swap từ {amount}$",
  mission_quick_buy_sell: "Mua/Bán {amount}$ tiền mã hoá sang VND",
  mission_fiat: "Nạp tối thiểu tương đương {amount}$ vào ví VNDR, NGNR, ZARR",
  mission_login: "Điểm danh mỗi ngày",
  mission_share: "Chia sẻ game trên Facebook",
  mission_renec: "Đổi Renec",
  mission_p2p: "Mua và bán P2P",
  mission_liquidity: "",
  mission_lending: "Ký gửi hoặc vay từ {amount}$ (USDT, USDC, BUSD)",
  mission_quiz: "Nhập môn crypto cùng Coinlympia",
  mission_invest: "Tạo thanh khoản",
  mission_imota_wallet : "Tải & Đăng kí ví Imota",
  mission_coin_to_zarr : "Sell ${amount} from coins to ZARR",
  mission_collect_zarr_badges : "Collect 11 badges in \"Sport collection\"",
  mission_coin_savi : "Tải ứng dụng & đăng ký CoinSavi",
  mission_social_follow: "Theo dõi các kênh mạng xã hội CoinSavi",
  mission_loyalty: "Sưu tập huy hiệu kỉ niệm Remitano 9 năm",
  mission_collect_ng_badges: "Collect 7 Joyful Badges in Loyalty Program",
  mission_login_continuously: "Login to Remitano for 7 consecutive days (in the event time)",
  mission_ng_trade: "Trade (buy/ sell) with total volume in the event time from {amount}",
  mission_azasend_linked_remitano: "Đăng ký và Liên kết tài khoản Azasend với Remitano",
  mission_joined_azasend_letter_hunt: "Tham gia AZA Hunt",
  spin: "Quay",
  more_spin: "Thêm lượt",
  my_bag: "Kho quà",
  rank: "Xếp hạng",
  you_have: "Bạn đang có",
  spin_turn: "lượt quay",
  mission_board: "Nhiệm vụ thêm lượt",
  mystery_planet: "Hành tinh bí ẩn",
  complete: "Thực hiện",
  completed: "Thực hiện",
  count_left: "Còn {total}",
  count_left_test: "{total} left {total} test",
  congratulation: "Chúc mừng!",
  check_reward: "Xem quà",
  share_reward: "Chia sẻ",
  valid_gift: "Quà của tôi",
  gift_history: "Lịch sử",
  receive: "Nhận ngay",
  open_wallet: "Mở ví",
  spin_now: "Chơi tiếp",
  use_now: "Dùng ngay",
  review_answers: "Xem lại đáp án",
  share_description: "Chia sẻ để có thêm 1 lượt chơi",
  share_now: "Chia sẻ ngay",
  unrank_title: "Chưa có xếp hạng",
  unrank_desc: "Hãy trở thành người đứng đầu trong bảng xếp hạng hôm nay!",
  use_gift_success: "Bạn đã sử dụng quà thành công",
  error: "Lỗi",
  daily: "Hôm nay",
  weekly: "Theo tuần",
  play_now: "Chơi ngay",
  continue_play: "Chơi tiếp",
  q_you_have: "Bạn có",
  turn: "lượt chơi",
  title_empty_game_passed: "Coinlympia thiếu dấu chân",
  sub_empty_game_passed:
    "Hành trình vạn dặm bắt đầu từ một bước chân. Đỉnh núi Coinlympia đang chờ",
  how_to_play: "Cách chơi",
  how_to_step_1: "1. Đăng nhập/đăng ký tài khoản Remitano để chơi",
  how_to_step_2: "2. Chọn “Chơi ngay” và trả lời 5 quiz ngẫu nhiên",
  how_to_step_3: "3. Xem kết quả hoặc chọn (x) để qua câu tiếp theo",
  how_to_step_4: "4. Xem lại thành tích ở “Bảng xếp hạng”",
  how_to_step_5: "5. Xem lại các quiz của bạn ở “Game đã chơi”",
  how_to_step_6: "6. Chia sẻ cho bạn bè để có thêm 1 lượt chơi/ngày và thách thức họ",
  how_to_step_7: "7. Lượt chơi này chỉ có giá trị trong ngày, không thể cộng dồn.",
  share_to_play: "Chia sẻ kiếm thêm lượt",
  ranking_chart: "Bảng xếp hạng",
  passed_game: "Game đã chơi",
  question: "Câu hỏi",
  answer: "Trả lời",
  read_more: "Xem thêm",
  sentence: "Câu",
  result: "Kết quả",
  required_login: "Bạn cần đăng nhập để tiếp tục.",
  winning_congrat: "Chúc mừng chiến thắng",
  correct_answers: "Trả lời đúng",
  you_received2: "Bạn nhận được",
  you_received: "Bạn nhận được",
  points: "điểm",
  you: "Bạn",
  week: "Tuần",
  your_rank: "Xếp hạng của bạn",
  q_title_share: "COINLYMPIA - KHÁM PHÁ KIẾN THỨC CRYPTO",
  finish_share: "Bạn đã thực hiện nhiệm vụ này ngày hôm nay",
  finishing_congrat: "Bạn đã hoàn thành",
  unknown_error_occurred: "Đã xảy ra lỗi không xác định",
  network_disconnected: "Lỗi kết nối Internet, vui lòng thử lại",
  other_something_went_wrong: "Đã xảy ra lỗi không xác định",
  login: "Đăng nhập",
  login_to_continue: "Vui lòng đăng nhập để chơi",
  out_of_spin_turn: "Bạn hết lượt quay, làm nhiệm vụ kiếm lượt nhé!",
  game_rule: "Thể lệ trò chơi",
  hint_1: "Ô dê! quà bự nè!",
  description_1: "Giao dịch càng nhiều, quà càng to đấy!",
  hint_2: "U là trời! Quà bự nghen!",
  description_2: "Giao dịch thêm để tậu ngay quà xịn nha các bác ôiiiiiii",
  goodluck_hint_1: "Thương thương!",
  goodluck_des_1: "Quà của bạn sẽ hiện ra vào lần quay sau nha!",
  goodluck_hint_2: "Chúc may mắn lần sau nhá!",
  goodluck_des_2: "Có công giao dịch có này nhận quà to!",
  goodluck_hint_3: "Lời thì thầm của các hành tinh",
  goodluck_des_3: "Thực hiện nhiệm vụ Swap để nhận lượt quay SIÊU may mắn nhé!",
  badluck_title_1: "Chậm lại chút",
  badluck_title_2: "Mách bạn nè",
  badluck_title_3: "Nói nghe nè",
  badluck_des_1: "Swap ngay hoặc Tiết kiệm lượt đến khi may mắn quay lại!",
  badluck_des_2: "Nếu Swap may mắn của bạn sẽ được nhân lên tới 18 lần lận!",
  badluck_des_3: "Thực hiện 1 nhiệm vụ Swap để thấy vận may được x18 lần bạn nhé!",
  warning: "Cảnh báo",
  confirm: "Đồng ý",
  cancel: "Hủy",
  continue_spin: "Tiếp tục quay",
  cancel_spin: "Dừng quay",
  expire: "HSD",
  just_won: "vừa trúng",
  version: "Phiên bản",
  spin_gift: "Lượt quay",
  good_luck_gift: "Chúc bạn may mắn lần sau",
  new_reward_title: "Quà tặng",
  spin_now_button: "Quay ngay",
};

export default words;
