import React from "react";
import { SButton, SText } from "base-components";
import { Image, View } from "react-native";
import COLORS from "utils/CommonColors";
import { sendEventLog } from "utils/EventLogger";
import styles from "./styles";
import Locale from "locale";
import { ILuckyTradeMission, MissionType } from "services/LuckyDrawService";

interface Props {
  index: number;
  item: ILuckyTradeMission;
  isNewVersionEnabled: boolean;
  onComplete: (missionType: MissionType, index: number) => void;
  kyc: boolean;
}

const NEW_MISSION_ICON = ["login", "share", "swap"];

const MissionItem: React.FC<Props> = ({
  item,
  index,
  onComplete,
  kyc,
  isNewVersionEnabled,
}) => {
  const missionTitle = React.useMemo(() => {
    return Locale.translate(`mission_${item.mission_type}`, {
      amount: item.mission_params?.min_volume || "",
    });
  }, [item.mission_params?.min_volume, item.mission_type]);

  const handleComplete = React.useCallback(() => {
    sendEventLog("click_luckydraw_mission", { mission_title: missionTitle });
    onComplete(item.mission_type, index);
  }, [index, item.mission_type, missionTitle, onComplete]);

  const missionReward = React.useMemo(() => {
    if (
      item.mission_type === "collect_zarr_badges" ||
      item.mission_type === "collect_ng_badges"
    ) {
      return `+${item.spin_amount} VIP ${Locale.translate("spin_turn")}`;
    }
    if (item.mission_type === "coin_to_zarr") {
      if (item?.mission_params?.min_volume === 250) {
        return `+${item.spin_amount} ${Locale.translate(
          "spin_turn"
        )} & 1 Loyalty special badge`;
      }
      if (item?.mission_params?.min_volume === 1000) {
        return `+${item.spin_amount} ${Locale.translate(
          "spin_turn"
        )} & 10 Loyalty special badge`;
      }
    }
    if (item.mission_type === "quick_buy_sell") {
      if (item?.mission_params?.min_volume === 1500) {
        return `+${item.spin_amount} ${Locale.translate("spin_turn")} Vip`;
      }
    }
    if (item.mission_type === "login_continuously") {
      return `+${item.spin_amount} ${Locale.translate(
        "spin_turn"
      )} & 1 Loyalty special badge`;
    }
    if (item.mission_type === "ng_trade") {
      return `+${item.spin_amount} ${Locale.translate(
        "spin_turn"
      )} & 3 Loyalty special badge`;
    }
    return `+${item.spin_amount} ${Locale.translate("spin_turn")}`;
  }, [item?.mission_params?.min_volume, item.mission_type, item.spin_amount]);

  const missionIcon = React.useMemo(() => {
    if (item.mission_type === "quick_buy_sell") {
      if (item?.mission_params?.min_volume === 1500) {
        return require("assets/spin-wheel/mission_quick_buy_sell_vip.png");
      }
    }
    if (item.mission_type === "ng_trade") {
      return require(`assets/spin-wheel/mission_ng_trade_${item?.mission_params?.min_volume}.png`);
    }
    if (["azasend_linked_remitano", "joined_azasend_letter_hunt"].includes(item.mission_type)) {
      return require("assets/spin-wheel/mission_zasend.png");
    }

    if (isNewVersionEnabled && NEW_MISSION_ICON.includes(item.mission_type)) {
      return require(`assets/spin-wheel/new_mission_${item.mission_type}.png`);
    }
    return require(`assets/spin-wheel/mission_${item.mission_type}.png`);
  }, [
    isNewVersionEnabled,
    item?.mission_params?.min_volume,
    item.mission_type,
  ]);

  return (
    <View style={[styles.container, { borderTopWidth: index === 0 ? 0 : 1 }]}>
      <Image source={missionIcon} style={styles.icon} />
      <View style={styles.centerBox}>
        <SText style={styles.missionName} size={15} bold>
          {missionTitle}
        </SText>
        <SText>{missionReward}</SText>
      </View>
      <View style={styles.itemCenter}>
        <SButton
          title={Locale.translate(`${kyc ? "complete" : "kyc"}`)}
          onPress={handleComplete}
          disable={!kyc ? false : item.limit_amount === item.completed_mission}
        />
        <SText style={styles.count} color={COLORS.yellow500}>
          {Locale.translate("count_left", {
            total: `${item.limit_amount - item.completed_mission}/${
              item.limit_amount
            }`,
          })}
        </SText>
      </View>
    </View>
  );
};

export default MissionItem;
